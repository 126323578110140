import React, { useState } from "react";
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { Link as GLink, navigate } from "gatsby";
import Copyright from "../components/Copyright";
import Logo from "../components/Logo";
import useStyles from "../helpers/styles";
import Wrapper from "../helpers/FormWrapper";
import * as CognitoService from "../services/cognito.service.js";
import config from "../config";
import "../helpers/getDestUrl";

const SignupForm = () => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [birthdate, setBirthdate] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [birthdateError, setBirthdateError] = useState(null);

  const onSubmit = () => {
    if (!areFieldsValid()) {
      return;
    }

    const user = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
    };

    if (birthdate) {
      user.birthdate = birthdate;
    }

    CognitoService.signup(user)
      .then(function (result) {
        //onSignupContinue({ $event: result.user });
        //console.log("---------- signup result: ", result);
        navigate("/confirmation", { state: { email } });
      })
      .catch(function (error) {
        //console.log("---------- signup error: ", error);
        switch (error.code) {
          case "UsernameExistsException":
            setEmailError("The provided email address is already registered.");
            break;
          case "InvalidPasswordException":
            setPasswordError(error.message);
            break;
          default:
            setEmailError(error.message);
        }
      });
  };

  const showSignupBtn = () => {
    const items = [];

    items.push(
      <Button
        type="button"
        key="submitButton"
        onClick={onSubmit}
        fullWidth
        variant="contained"
        color="primary"
        className="submit-btn"
      >
        Continue
      </Button>
    );

    items.push(
      <Grid container key="alreadyHaveAccount" justify="flex-end">
        <Grid item>
          <Typography variant="body2">
            <GLink to="/" className={classes.primary} variant="body2">
              Already have an account? Sign in
            </GLink>
          </Typography>
        </Grid>
      </Grid>
    );

    return items;
  };

  const showBirthdateInput = () => {
    if (config.includeBirthdate) {
      return (
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            error={Boolean(birthdateError)}
            helperText={birthdateError}
            id="birthdate"
            name="birthdate"
            label="Birthdate"
            onChange={({ target }) => {
              setBirthdate(target.value);
            }}
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      );
    }

    return null;
  };

  const areFieldsValid = () => {
    resetErrors();
    var valid = true;

    if (!firstName || !firstName.trim()) {
      setFirstNameError("First Name is required");
      valid = false;
    }

    if (!firstName || !lastName.trim()) {
      setLastNameError("Last Name is required");
      valid = false;
    }

    if (!password || password.trim().length < 8) {
      setPasswordError(`Password must be at least 8 characters long.
        and should include numbers, uppercase and lowercase letters`);
      valid = false;
    } else if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      valid = false;
    }

    if (!email || !email.trim()) {
      setEmailError("Invalid Email.");
      valid = false;
    }

    if (!isValidDate()) {
      setBirthdateError("Invalid date.");
      valid = false;
    }

    return valid;
  };

  const isValidDate = () => {
    if (birthdate) {
      let date = new Date(birthdate);
      let currentDate = new Date();

      if (isNaN(date.getTime()) || date > currentDate) return false;
    }

    return true;
  };

  const resetErrors = () => {
    setFirstNameError(null);
    setLastNameError(null);
    setBirthdateError(null);
    setEmailError(null);
    setPasswordError(null);
  };

  return (
    <div className={classes.paper}>
      <Logo />
      <Typography component="h1" variant="h5">
        Sign up
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="firstName"
              onChange={({ target }) => {
                setFirstName(target.value);
              }}
              variant="outlined"
              required
              fullWidth
              error={Boolean(firstNameError)}
              helperText={firstNameError}
              id="firstName"
              label="First Name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              error={Boolean(lastNameError)}
              helperText={lastNameError}
              id="lastName"
              label="Last Name"
              name="lastName"
              onChange={({ target }) => {
                setLastName(target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              error={Boolean(emailError)}
              helperText={emailError}
              id="email"
              label="Email Address"
              name="email"
              onChange={({ target }) => {
                setEmail(target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              error={Boolean(passwordError)}
              helperText={passwordError}
              label="Password"
              name="password"
              onChange={({ target }) => {
                setPassword(target.value);
              }}
              type="password"
              id="password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="confirm_password"
              onChange={({ target }) => {
                setConfirmPassword(target.value);
              }}
              label="Confirm Password"
              type="password"
              id="confirm_password"
            />
          </Grid>

          {showBirthdateInput()}

          <Grid item xs={12}>
            <Typography variant="body2">
              By clicking "Continue", you agree to the&nbsp;
              <Link
                href="http://hello.mapquest.com/terms-of-use/"
                target="_blank"
              >
                Terms
              </Link>
              &nbsp; and &nbsp;
              <Link href="https://hello.mapquest.com/privacy" target="_blank">
                Privacy&nbsp;Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
        {showSignupBtn()}
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </div>
  );
};

export default function SignUp() {
  return (
    <Wrapper>
      <SignupForm />
    </Wrapper>
  );
}
